import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const CLIENT_ID = "c5306870-0891-43f5-905e-091b0bcdac6e";
const TENANT_ID = "5c4511de-f91f-43d5-a9ba-2788d158fc8d";

const msalConfig: Configuration = {
    auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        //@ts-ignore
        redirectUri: `https://wa-gitbook-integration-prod.azurewebsites.net/`,
        navigateToLoginRequestUrl: true
    },
    cache: { cacheLocation: "localStorage" },
};

const requestMsal = {
    scopes: [`api://${CLIENT_ID}/User.Read`],
};

const pca = new PublicClientApplication(msalConfig);

export { CLIENT_ID, TENANT_ID, msalConfig, requestMsal, pca };
