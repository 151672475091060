import { axiosInstance } from 'config/axiosConfig';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridLoader } from 'react-spinners';

function Authenticator() {
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        document.title = 'Authenticator';
        let spaceName = searchParams.get("space");
        if (!spaceName){
            setErrorMessage("Space name is missing.");
            setIsLoading(false);
            return;
        }
        redirectToAuthorizedUrl(spaceName);
    }, []);

    const redirectToAuthorizedUrl = async (spaceName: string) => {
        const res = (await axiosInstance.get("/api/sign/GetSignedUrl?spaceName=" + spaceName));
        if (res.data) {
            window.location.replace(res.data);
        }
        else {
            //@ts-ignore
            setErrorMessage("Space does not exist in Gitbook or it is not configured for auth in the Authenticator.");
        }
        setIsLoading(false);
    }

    return (
        <div>
            <h1>Hello, Gitbook!</h1>
            {isLoading ?
                <p><span style={{paddingRight: "8px"}}>
                    <GridLoader color="#000" size={3}/>
                    </span>Logging in your Microsoft account before redirecting to Gitbook...</p>
                : <p>{errorMessage}</p>}
        </div>
    )
}

export default Authenticator;