export default function Home (){

  return (
    <div>
      <h1>Bonsai Gitbook Authenticator</h1> 
      <p>Proudly authenticating Bonsai employees for <b>view access in Gitbook</b> docs since 2023</p>

      <h3>How to use:</h3>
      <ol>
        <li>You get a <b>link to the space</b> you want to access (a human being gives it to you), which can be one of the following:</li>
        <ul>
          <li><b>Authenticator link</b> – looks similar to <a href='https://wa-gitbook-integration-prod.azurewebsites.net/auth?space=auth-test-space-2'>https://wa-gitbook-integration-prod.azurewebsites.net/auth?space=auth-test-space-2</a></li>
          <li><b>Gitbook page link</b> – looks similar to <a href='https://docs.bonsai.tech/auth-test-space-2'>https://docs.bonsai.tech/auth-test-space-2</a> (this link will redirect to Authenticator link)</li>
        </ul>
        <li>You <b>open/copy the link</b> in the browser </li>
        <li>If you are <b>already logged in</b> into your Microsoft Account, the Authenticator will simply redirect you to the Gitbook page </li>
        <li>If you are <b>not logged in yet</b>, a <b>popup</b> will appear asking you to log in into your Microsoft account and after successful login, the Authenticator will <b>redirect</b> you to the Gitbook page  </li>
      </ol>      
    </div>
  );

}
