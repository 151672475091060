import Authenticator from "components/Authentificator";
import Home from "components/Home";
import ReportIssue from "components/ReportIssue";


const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/issue',
    element: <ReportIssue />
  },
  {
    path: '/auth',
    element: <Authenticator />
  }
];

export default AppRoutes;
