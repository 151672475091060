import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
//@ts-ignore
const root = createRoot(rootElement);

if (window.location.hash !== ''){
  console.warn("hash found" + window.location.hash);
  // This is a workaround to deal with race condition between the app and Azure AD popup
  // Without this, ocasionally the response from Azure AD login will not be processed and the app will be displayed in the popup instead
  // This workaround was found at this thread: 
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5091
}
else{
  root.render(
    //@ts-ignore
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
