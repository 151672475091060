import { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { MsalProvider } from '@azure/msal-react';
import { pca } from './config/authConfig';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <MsalProvider instance={pca}>
        {/* <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}> */}
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
        </Routes>
      </Layout>
      {/* </MsalAuthenticationTemplate> */}
      </MsalProvider>
    );
  }
}
