export default function ReportIssue (){

  return (
    <div>
      <h1>Bonsai Gitbook Authenticator</h1> 
      <p>Proudly authenticating Bonsai employees for <b>view access in Gitbook</b> docs since 2023</p>

      <h3>Report an issue: </h3>
      <ol>
        <li>Check open issues under <a href="https://dev.azure.com/techbonsai/dev_team%20general/_workitems/edit/6113">Bonsai Gitbook Authenticator - Bugs</a> to make sure that the issue is <b>not already reported </b></li>
        <li>Open <b>new work item</b> under <a href="https://dev.azure.com/techbonsai/dev_team%20general/_workitems/edit/6113">Bonsai Gitbook Authenticator - Bugs</a></li>
        <ul>
          <li><b>Link: </b> Child</li>
          <li><b>Work item type:</b> Issue</li>
        </ul>
        <li>Populate item description with these information: </li>
        <ul>
          <li><b>Reproduction steps</b> – exact steps you did before the issue occurred</li>
          <li><b>Actual result </b> – in short, describe what happened</li>
          <li><b>Expected result  </b> – in short, describe what you were expecting to happen</li>
          <li>If possible, also provide: </li>
          <ul>
          <li><b>Screenshots </b> of the issue</li>
          <li><b>Exact date and time </b> of the issue</li>
        </ul>
        </ul>
        <li>Any further communication regarding the issue should go through the created work issue comments </li>
      </ol>      
    </div>
  );

}
