import axios, { AxiosError } from "axios";
import { pca, requestMsal } from "./authConfig";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async config => {
        const instance = pca;

        const accessTokenRequest = {
            account: instance.getAllAccounts()[0] || undefined,
            scopes: requestMsal.scopes,
        };
        
        return instance.ssoSilent(accessTokenRequest).then(({ accessToken }) => {
            if (config.headers) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return Promise.resolve(config);
        }).catch((error) => {
            return instance.loginPopup(accessTokenRequest).then(({accessToken})=> {
                if (config.headers) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return Promise.resolve(config);
            })
        })
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    async response => {
        return response;
    },
    async (error: AxiosError) => {
        console.warn(error.message);
        return error;
    }
);

export { axiosInstance };
